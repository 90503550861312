<template>
  <skeleton v-if="skeleton" :width="skeletonWidth" height="12" />
  <nuxt-link
    v-else-if="url && !isExternal && !isModal"
    :to="$contextPath(url)"
    :class="[classes, 'inline-block']"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <icon v-if="loading" icon="loader" class="mx-auto" />
    <template v-else>
      <icon
        v-if="icon && prefixIcon"
        :icon="icon"
        :class="{ 'ms-4': !noMargin, 'ml-4': direction === 'rtl' && !noMargin }"
        :color="iconColor"
      />
      <slot />
    </template>
    <icon
      v-if="icon && !prefixIcon"
      :icon="icon"
      :class="{ 'ms-4': !noMargin }"
      :color="iconColor"
    />
  </nuxt-link>
  <a
    v-else-if="externalUrl || isExternal"
    :href="externalLink"
    :target="target"
    :class="[classes, 'inline-block']"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <icon v-if="loading" icon="loader" class="mx-auto" />
    <slot v-else />
    <icon
      v-if="icon"
      :icon="icon"
      :class="{ 'ms-4': !noMargin, 'rotate-180': direction === 'rtl' }"
      :color="iconColor"
    />
  </a>
  <button
    v-else-if="isModal"
    :class="classes"
    :disabled="disable"
    v-bind="$attrs"
    @click.stop="openModal(modalName)"
  >
    <icon v-if="loading" icon="loader" class="mx-auto" />
    <slot v-else />
    <icon
      v-if="icon"
      :icon="icon"
      :class="{ 'ms-4': !noMargin, 'rotate-180': direction === 'rtl' }"
      :color="iconColor"
    />
  </button>
  <button
    v-else
    :class="classes"
    :disabled="disable"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div v-if="loading" class="dot-flashing mx-auto" />
    <template v-else>
      <icon
        v-if="icon && prefixIcon"
        :icon="icon"
        :class="{ 'ms-4': !noMargin, 'ml-4': direction === 'rtl' && !noMargin }"
        :color="iconColor"
      />
      <slot />
    </template>
    <icon
      v-if="icon && !prefixIcon"
      :icon="icon"
      :class="{ 'ms-4': !noMargin, 'rotate-180': direction === 'rtl' }"
      :color="iconColor"
    />
  </button>
</template>

<script>
import VueTypes from 'vue-types'
import { mapActions } from 'vuex'
import Icon from '~/components/atoms/icon'
import Skeleton from '~/components/utils/skeleton'
import { isExternalLink, isModalLink } from '~/services/url'

export default {
  name: 'UiButton',
  components: {
    Icon,
    Skeleton,
  },
  props: {
    isSlim: VueTypes.bool.def(false),
    slimYPadding: VueTypes.bool.def(false),
    noXPadding: VueTypes.bool.def(false),
    noYPadding: VueTypes.bool.def(false),
    prefixIcon: VueTypes.bool.def(false),
    justify: VueTypes.bool.def(false),
    noMargin: VueTypes.bool.def(false),
    type: VueTypes.oneOf([
      'primary',
      'secondary',
      'tertiary',
      'tertiary-light',
      'secondary-dark',
      'white',
      'dark',
      'info',
      'light',
      'white-no-hover',
    ]).def('primary'),
    customClasses: {
      type: String,
      default: null,
    },
    excludeHoverClasses: {
      type: Boolean,
      default: false,
    },
    blank: VueTypes.bool.def(false),
    disable: VueTypes.bool.def(false),
    loading: VueTypes.bool.def(false),
    url: VueTypes.string.def(undefined),
    externalUrl: VueTypes.string.def(undefined),
    hasWordBreak: VueTypes.bool.def(false),
    skeleton: VueTypes.bool.def(false),
    isRounded: VueTypes.bool.def(false),
    hasDropShadow: VueTypes.bool.def(false),
    skeletonWidth: VueTypes.string.def('full'),
    icon: {
      type: String,
      default: '',
    },
    direction: VueTypes.string.def('ltr'),
  },
  computed: {
    isExternal() {
      return isExternalLink(this.url)
    },
    target() {
      return this.blank ? '_blank' : '_self'
    },
    isModal() {
      return isModalLink(this.url)
    },
    externalLink() {
      const { url, externalUrl } = this
      return externalUrl || (isExternalLink(url) ? url : '')
    },
    modalName() {
      return this.url.replace('modal://', '')
    },
    classes() {
      const {
        isSlim,
        slimYPadding,
        noYPadding,
        noXPadding,
        prefixIcon,
        hasWordBreak,
        disable,
        my,
        justify,
        loading,
        icon,
        isRounded,
        hasDropShadow,
      } = this
      const base = 'rounded font-bold text-center text-base leading-none'
      const borderRadius = isRounded ? 'rounded-lg' : ''
      const dropShadow = hasDropShadow ? 'shadow-lg' : ''
      const display = icon ? 'inline-flex' : 'inline-block'
      const flexClasses = prefixIcon ? 'align-center justify-center' : ''

      let spacingY = 'py-4'
      if (noYPadding) spacingY = 'py-0'
      else if (slimYPadding) spacingY = 'py-2'

      let spacingX = 'px-8'
      if (noXPadding) spacingX = 'px-0'
      else if (isSlim) spacingX = 'px-4'

      const wordBreak = hasWordBreak ? 'word-break' : 'truncate'
      const disabled =
        'opacity-50 cursor-not-allowed bg-gray text-gray-black border-0'
      const active = !disable
        ? 'cursor-pointer active:bounce active:border-solid'
        : ''
      const loadingAnimation = loading ? 'button-loading' : ''

      const type = this.getTypeClasses()
      const margin = `my-${my}`

      return [
        base,
        borderRadius,
        dropShadow,
        display,
        type,
        spacingX,
        spacingY,
        active,
        wordBreak,
        flexClasses,
        {
          'w-full': justify,
        },
        {
          [disabled]: disable,
        },
        {
          [margin]: my,
        },
        loadingAnimation,
      ]
    },
    iconColor() {
      if (!this.icon) return ''

      const iconColor = {
        primary: 'white',
        secondary: 'white',
        white: 'white',
      }

      return iconColor[this.type]
    },
  },
  methods: {
    ...mapActions('ui/content-modal', ['openModal']),
    getTypeClasses() {
      const typeClasses = {
        primary: 'border-0 bg-cta text-black',
        secondary: 'border border-cta text-cta',
        'secondary-dark':
          'border border-tertiary-darker bg-transparent text-tertiary-darker',
        tertiary: 'border border-white text-white',
        'tertiary-light':
          'border-0 bg-tertiary-light border-white text-primary',
        white: 'bg-white text-primary',
        'white-no-hover': 'bg-white text-primary',
        dark: 'bg-primary text-white',
        info: 'border border-info-light-dark bg-white text-info-light-dark',
      }

      const hoverClasses = {
        primary: 'hover:bg-cta-hover',
        secondary: 'hover:border-gray hover:text-gray',
        'secondary-dark': 'hover:bg-gray-lighter',
        tertiary: 'border border-white text-white',
        'tertiary-light': 'border-0 bg-tertiary-light border-white',
        white: 'hover:bg-gray-light',
        'white-no-hover': 'bg-white text-primary',
        dark: 'hover:bg-primary-light',
        info: 'border border-info-light-dark bg-white text-info-light-dark',
      }

      const focusClasses = {
        primary:
          'focus:shadow-border-sm transition-colors transition-shadow duration-300',
        secondary: '',
        'secondary-dark': 'secondary-dark-focus-shadow',
        tertiary: '',
        'tertiary-light': '',
        white: '',
        'white-no-hover': '',
        dark: '',
        info: '',
      }

      if (this.disable) return ''

      return [
        this.excludeHoverClasses ? '' : hoverClasses[this.type],
        this.customClasses ? this.customClasses : typeClasses[this.type],
        focusClasses[this.type],
      ]
    },
  },
}
</script>
<style lang="scss">
.secondary-dark-focus-shadow:focus {
  box-shadow: 0 0 0 2px rgba(0, 107, 229, 0.3);
}
.button-loading {
  content: '';
  pointer-events: none;
  cursor: not-allowed !important;
  .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ecf9f2;
    color: #ecf9f2;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
    }

    &::before {
      left: -15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #008549;
      color: #008549;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 0s;
    }

    &::after {
      left: 15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #008549;
      color: #008549;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 1s;
    }
  }
  @keyframes dotFlashing {
    0% {
      background-color: #008549;
    }
    50%,
    100% {
      background-color: #ecf9f2;
    }
  }
}
</style>
